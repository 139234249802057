<template>
  <!-- Desc: Bidding startegy view at the campaign level -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <div
      v-if="adjustBids"
      class="u-position-relative"
    >
      <loader
        v-show="campaignInfo.status === 'loading'"
        class="fill--parent"
        :loading="campaignInfo.status === 'loading'"
        :color="'#3684bb'"
      />
      <div class="u-spacing-p-l u-font-size-6">
        <div class="u-spacing-pb-l u-text-case-upper u-font-weight-600">
          Bid Adjustment ({{ selections.length }} placement{{
            selections.length > 1 ? `'s` : ''
          }}
          selected)
        </div>
        <div class="u-display-flex">
          <div
            class="u-border-right u-border-color-grey-xxx-light u-spacing-pr-xxl"
          >
            <div class="u-spacing-pb-xs u-font-weight-600">Adjust Bid:</div>
            <div
              class="u-display-flex percentBox u-flex-0 u-flex-align-items-center u-width-20"
            >
              <span
                class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
              >
                %
              </span>
              <input
                v-model="placementBid"
                type="number"
                class="rb-input currency-input rb-width u-display-flex u-flex-align-items-center"
                step="0.01"
              />
            </div>
            <div class="u-spacing-mt-xs">
              <span
                class="u-font-size-7 u-display-flex u-flex-align-items-center u-color-grey-lighter"
              >
                <rb-icon
                  class="rb-icon--medium u-color-grey-lighter u-spacing-mr-xs"
                  :icon="'info-circle-fill'"
                />
                Change by Percentage
              </span>
            </div>
          </div>
          <div class="u-flex-4 u-display-flex u-flex-align-items-center">
            <div
              class="u-font-size-5 u-spacing-pl-xxl u-display-flex u-color-grey-lighter"
            >
              <div>
                <rb-icon
                  class="rb-icon--medium u-color-grey-lighter u-spacing-mr-xs"
                  :icon="'info-circle-fill'"
                />
              </div>
              <div class="u-color-grey-lighter">
                Choose a percentage between 0% and 900%. <br />
                Example: A $1.00 bid will remain $1.00 for this placement.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :disable-left-btn="!placementBid"
          btn-left-text="Apply"
          class="u-spacing-ph-l"
          :button-size="'s'"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onUpdate"
        />
      </div>
    </div>
    <div
      v-if="selections.length && !adjustBids"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import loader from '@/components/basic/loader';
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');

export default {
  components: {
    tableWrapper,
    actionsBar,
    loader,
    footerButtons
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      placementMapActionKeyMap: {
        'Top of search (first page)': 'new_placement_top_percentage',
        'Product pages': 'new_placement_product_page_percentage'
      },
      selections: [],
      getState: null,
      requestPayload: null,
      updateMetadata: null,
      adjustBids: false,
      widget: {},
      placementBid: null,
      tableGridAPI: null,
      allowedActions: [
        {
          icon: 'dollar',
          title: 'Bid Adjustment',
          id: 'adjustBids'
        }
      ],
      tabConfig: null
    };
  },

  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      if (this.tabMetadata?.widgets[0]) {
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            // enableDownload: true,
            enableManageColumns: !this.selections.length,
            headerContainerGridStyles: {
              display: this.selections.length ? 'none' : 'flex',
              'justify-content': 'flex-end'
            },
            searchParams: {
              show: false
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 45,
            isRowSelectable: function (params) {
              if (params.data?.placement === 'Rest of search') {
                return false;
              }
              return true;
            }
          }
        };
      }
      return obj;
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': [
        {
          dimension: 'ams_cm_placement_spend',
          direction: 'DESC'
        }
      ],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':campaign_id': this.entityId
      },
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.entityId}`
      }
    };
  },
  methods: {
    formatRequestPayload(selection) {
      if (!this.placementMapActionKeyMap[selection.data.placement]) {
        this.$snackbar.open({
          message: 'Cannot take action on "Rest of search" placement type.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
        return null;
      }
      const reqPayload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':campaignName': this.campaignInfo?.data?.name,
        ':entityId': this.entityId,
        ':pageUrl': window.location.href,
        ':placement': selection.data.placement,
        ':view_newPercentage': this.placementBid + '%',
        ':view_oldPercentage':
          selection.data.ams_cm_placement_bid_adjustment + '%',
        ':newPercentage': this.placementBid.toString(),
        ':oldPercentage':
          selection.data.ams_cm_placement_bid_adjustment.toString()
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(reqPayload, item, reqValues[item], selection);
      }
      reqPayload.actionPayload[
        this.placementMapActionKeyMap[selection.data.placement]
      ] = this.placementBid;
      return reqPayload;
    },
    onUpdate() {
      const payloadArr = [];
      for (const selection of this.selections) {
        const payload = this.formatRequestPayload(selection);
        if (payload) {
          payloadArr.push(payload);
        }
      }
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName: {
          singular: 'Campaign Placement',
          plural: 'Campaign Placements'
        }
      });
      this.closeActionBar();
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      this[actionId] = true;
    },
    handleSelectionChange(selections, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = selections;
      if (!selections?.length) {
        this.closeActionBar();
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.adjustBids = null;
      this.selections = [];
    }
  }
};
</script>
<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}
.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input {
  border-left: 0;
  padding-left: 5px;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-right: 0;
  padding-left: 8px;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
</style>
